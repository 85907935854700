<template>
  <div class="add-container">
    <div class="top-title">
      <div>
        <span>
          Order Detail #{{ detailData.val.unique_id }}
          <icon-copy class="copy-color" @click="copyVal(detailData.val.unique_id)" />
          <a-divider direction="vertical" />
          <span class="create-time">Created At: {{ detailData.val.created_at }} </span>
          <span class="detail-status">{{ detailData.val.status_desc }}</span>
        </span>
      </div>
      <div class="operateBtn">
        <a-space>
          <a-button v-if="status === 3" type="primary" shape="round" @click="shipped">Mark as
            Shipped</a-button>
          <a-button v-if="status === 2" type="primary" shape="round" @click="print">Print</a-button>
          <a-popconfirm content="Are you sure you want to duplicate?" type="warning" ok-text="Duplicate"
            cancel-text="Back" @ok="duplicate" class="warnPop">
            <a-button v-if="status === 2 || status === 3 || status === 4" shape="round">Duplicate
            </a-button>
          </a-popconfirm>
          <a-popconfirm content="Are you sure you want to create return order(s)?" type="warning" ok-text="Create"
            cancel-text="Back" @ok="returnLable" class="warnPop">
            <a-button v-if="status === 2 || status === 3 || status === 4" shape="round">Return Order
            </a-button>
          </a-popconfirm>
          <a-popconfirm content="Are you sure to cancel selected order(s)?" type="warning" ok-text="Cancel Order(s)"
            cancel-text="Back" @ok="cancelOrder" class="warnPop" position="br">
            <a-button v-if="status === 2 || status === 3 || status === 4" shape="round" status="warning">
              Cancel
            </a-button>
          </a-popconfirm>
          <a-button type="primary" shape="round" v-if="(detailData.val.original_order_id > 0)"
            @click="goOriginal(detailData.val.original_order_id)">Original Order</a-button>
        </a-space>
      </div>
    </div>
    <div class="detail-box">
      <a-collapse expand-icon-position="right" :default-active-key="['1']">
        <a-collapse-item key="1">
          <template #expand-icon="{ active }">
            <a-button shape="circle" v-if="active">
              <icon-double-down />
            </a-button>
            <a-button shape="circle" v-else>
              <icon-double-up />
            </a-button>
          </template>
          <template #header>
            <span class="collapse-title">Shipper, Return, Recipient Address </span>
          </template>
          <a-row :gutter="[20, 12]">
            <a-col :span="8">
              <a-descriptions title="Shipper" :column="1">
                <a-descriptions-item label="Name">
                  {{ detailData.val.from_name }}
                </a-descriptions-item>
                <a-descriptions-item label="Company">
                  <span v-if="detailData.val.from_company">{{ detailData.val.from_company }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Address">
                  {{ detailData.val.from_street1 }}
                </a-descriptions-item>
                <a-descriptions-item label="">
                  {{ detailData.val.from_city }}, {{ detailData.val.from_state }}, {{ detailData.val.from_zip }}, US
                </a-descriptions-item>
                <a-descriptions-item label="Phone">
                  {{ detailData.val.from_phone }}
                  <span v-if="detailData.val.from_extension">
                    <span>&nbsp;x&nbsp;</span>
                    {{ detailData.val.from_extension }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Email">
                  <span v-if="detailData.val.from_email">{{ detailData.val.from_email }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
            <a-col :span="8">
              <a-descriptions title="Return" :column="1">
                <a-descriptions-item label="Name">
                  <span v-if="detailData.val.return_name">{{ detailData.val.return_name }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Company">
                  <span v-if="detailData.val.return_company">{{ detailData.val.return_company }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Address">
                  <span v-if="detailData.val.return_street1">{{ detailData.val.return_street1 }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="">
                  <span v-if="detailData.val.return_zip && detailData.val.return_city && detailData.val.return_state">
                    {{ detailData.val.return_city }}, {{ detailData.val.return_state }}, {{ detailData.val.return_zip
                    }}, US
                  </span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Phone">
                  <span v-if="detailData.val.return_phone">
                    {{ detailData.val.return_phone }}
                    <span v-if="detailData.val.return_extension">
                      <span>&nbsp;x&nbsp;</span>
                      {{ detailData.val.return_extension }}
                    </span>
                  </span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Email">
                  <span v-if="detailData.val.return_email">{{ detailData.val.return_email }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
            <a-col :span="8">
              <a-descriptions title="Recipient" :column="1">
                <a-descriptions-item label="Name">
                  {{ detailData.val.to_name }}
                </a-descriptions-item>
                <a-descriptions-item label="Company">
                  <span v-if="detailData.val.to_company">{{ detailData.val.to_company }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
                <a-descriptions-item label="Address">
                  {{ detailData.val.to_street1 }}
                </a-descriptions-item>
                <a-descriptions-item label="">
                  {{ detailData.val.to_city }}, {{ detailData.val.to_state }}, {{ detailData.val.to_zip }}, US
                </a-descriptions-item>
                <a-descriptions-item label="Phone">
                  {{ detailData.val.to_phone }}
                  <span v-if="detailData.val.to_extension">
                    <span>&nbsp;x&nbsp;</span>
                    {{ detailData.val.to_extension }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Email">
                  <span v-if="detailData.val.to_email">{{ detailData.val.to_email }}</span>
                  <span v-else>N/A</span>
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
          </a-row>
        </a-collapse-item>
      </a-collapse>
      <a-card title="Other Information" style="margin-top:24px;">
        <a-row :gutter="[20, 12]">
          <a-col :span="8">
            <a-descriptions :column="1">
              <a-descriptions-item label="PO Number">
                <span v-if="detailData.val.ponumber">{{ detailData.val.ponumber }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
              <a-descriptions-item label="Invoice Number">
                <span v-if="detailData.val.invoice_number">{{ detailData.val.invoice_number }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
              <a-descriptions-item label="Reference">
                <span v-if="detailData.val.reference">{{ detailData.val.reference }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
              <a-descriptions-item label="Order ID">
                <span v-if="detailData.val.unique_id">{{ detailData.val.unique_id }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
              <a-descriptions-item label="Tracking">
                <span v-if="detailData.val.tracking_id">{{ detailData.val.tracking_id }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :span="8">
            <a-descriptions :column="1">
              <a-descriptions-item label="Ship Date">
                <span v-if="detailData.val.ship_date">{{ detailData.val.ship_date }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
              <a-descriptions-item label="Signature Option">
                <span>{{ detailData.val.signature_option_desc }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="Drop Off Type">
                <span>{{ detailData.val.dropoff_type_desc }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="Service Type">
                <span v-if="detailData.val.service_type_desc">{{ detailData.val.service_type_desc }}</span>
                <span v-else>N/A</span>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="12">
                <a-descriptions title="Settled Detail" :column="1">
                  <a-descriptions-item label="Est. Cost">
                    <span class="default-text" v-if="settleDetail.est_cost === 'N/A'">N/A</span>
                    <a-tooltip v-else>
                      <span class="default-text">${{ settleDetail.est_cost }}</span>
                      <template #content>
                        <div v-for="val, k, i in detailData.val.quote_detail" :key="i">
                          <div>
                            <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                            <span v-else>-{{ k }}:</span>
                            <span> {{ val }}</span>
                          </div>
                        </div>
                      </template>
                    </a-tooltip>
                  </a-descriptions-item>
                  <a-descriptions-item label="Refund">
                    <span class="default-text dlivered" v-if="Number(settleDetail.refund) > 0">${{
                      settleDetail.refund
                    }}</span>
                    <span class="default-text exceptional" v-else-if="Number(settleDetail.refund) < 0">${{
                      settleDetail.refund
                    }}</span>
                    <span class="default-text" v-else>N/A</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="Act. Cost">
                    <span class="default-text" v-if="settleDetail.act_cost === 'N/A'">
                      {{ settleDetail.act_cost }}
                    </span>
                    <span v-else>
                      {{ settleDetail.act_cost }}
                    </span>
                  </a-descriptions-item>
                  <a-descriptions-item label="Adjust">
                    <span class="default-text dlivered" v-if="Number(settleDetail.adjust) > 0">${{
                      settleDetail.adjust
                    }}</span>
                    <span class="default-text exceptional" v-else-if="Number(settleDetail.adjust) < 0">${{
                      settleDetail.adjust
                    }}</span>
                    <span class="default-text" v-else>{{ settleDetail.adjust }}</span>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions title="Unsettled Detail" :column="1">
                  <a-descriptions-item label="Est. Cost">
                    <a-tooltip>
                      <span class="default-text">${{ unsettleDetail.est_cost }}</span>
                      <template #content>
                        <div v-for="val, k, i in detailData.val.quote_detail" :key="i">
                          <div>
                            <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                            <span v-else>-{{ k }}:</span>
                            <span> {{ val }}</span>
                          </div>
                        </div>
                      </template>
                    </a-tooltip>
                  </a-descriptions-item>
                  <a-descriptions-item label="Refund">
                    <span class="status dlivered" v-if="Number(unsettleDetail.refund) > 0">${{
                      unsettleDetail.refund
                    }}</span>
                    <span class="status exceptional" v-else-if="Number(unsettleDetail.refund) < 0">${{
                      unsettleDetail.refund
                    }}</span>
                    <span class="default-text" v-else>N/A</span>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
      <a-space style="margin:24px 0;">
        <span class="shipments-title">Shipment(s) Information</span>
        <div v-if="status === 3 || status === 4" class="menu-list">
          <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="[navselect]">
            <a-menu-item :key="val" v-for="val, k in navlist">{{ k }}</a-menu-item>
          </a-menu>
        </div>
      </a-space>
      <div style="margin-bottom:80px;">
        <a-config-provider :locale="enUS">
          <a-table :data="detailPackage.val" :bordered="false" :pagination="false"
            :row-selection="status === 2 || status === 3 || status === 4 ? rowSelection : null" @select-all="changmsg"
            @select="selectVal" :selected-keys="form.operate_id" :scroll="{ x: tableWidth, y: 800 }" :loading="loading">
            <template #empty>
              <div v-if="detailPackage.val.length === 0 && loading === false" class="empty-cont">
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" class="skeleton-box">
              </div>
            </template>
            <template #columns>
              <a-table-column title="No." data-index="no" :width="80"></a-table-column>
              <a-table-column title="Tracking ID" :width="180">
                <template #cell="{ record }">
                  <div v-if="record.tracking">
                    <span>{{ record.tracking }}</span>
                    <a-tooltip content="Copy">
                      <icon-copy class="margin-l-10" @click.stop="copyVal(record.tracking)" />
                    </a-tooltip>
                  </div>
                  <div v-else>N/A</div>
                </template>
              </a-table-column>
              <a-table-column title="Status" data-index="status" :width="180">
                <template #cell="{ record }">
                  <a-tag color="orange" v-if="record.status === 'Awaiting Shipment'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="blue" v-if="record.status === 'In Transit'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="red" v-if="record.status === 'Exceptional'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="green" v-if="record.status === 'Delivered'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="cyan" v-if="record.status === 'Dispatching'">{{
                    record.status
                  }}</a-tag>
                  <a-tag v-if="record.status === 'Canceled'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="purple" v-if="record.status === 'Callback'">{{
                    record.status
                  }}</a-tag>
                  <a-tag color="purple" v-if="record.status === 'Change Address'">{{
                    record.status
                  }}</a-tag>
                </template>
              </a-table-column>
              <a-table-column title="Sku" data-index="sku" :width="120">
                <template #cell="{ record }">
                  <span v-if="record.sku">{{ record.sku }}</span>
                  <span v-else>N/A</span>
                </template>
              </a-table-column>
              <a-table-column title="Demension(In)" data-index="demension" :width="150"></a-table-column>
              <a-table-column title="Weight(Lb)" data-index="weight" :width="120"></a-table-column>
              <a-table-column title="Declared Value" data-index="insurance" :width="140">
                <template #cell="{ record }">${{ record.insurance }}</template>
              </a-table-column>
              <a-table-column title="Est. Cost" data-index="estcost" :width="120">
                <template #cell="{ record }">
                  <a-tooltip position="right">
                    <a-tag>${{ record.estcost }}</a-tag>
                    <template #content>
                      <div v-for="val, k, i in record.quoteDetail" :key="i">
                        <div>
                          <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                          <span v-else>-{{ k }}:</span>
                          <span> {{ val }}</span>
                        </div>
                      </div>
                    </template>
                  </a-tooltip>
                </template>
              </a-table-column>
              <a-table-column title="Refund" data-index="refund" :width="120">
                <template #cell="{ record }">
                  <a-tag color="green" v-if="Number(record.refund > 0)">${{ record.refund }}</a-tag>
                  <a-tag color="red" v-else-if="Number(record.refund < 0)">${{ record.refund }}</a-tag>
                  <a-tag v-else>N/A</a-tag>
                </template>
              </a-table-column>
              <a-table-column title="Act. Cost" data-index="actcost" :width="120">
                <template #cell="{ record }">
                  <a-tag v-if="record.actcost === 'N/A'">N/A</a-tag>
                  <a-tag v-else>${{ record.actcost }}</a-tag>
                </template>
              </a-table-column>
              <a-table-column title="Adjustment" data-index="adjust" :width="120">
                <template #cell="{ record }">
                  <a-tag color="green" v-if="Number(record.adjust > 0)">${{ record.adjust }}</a-tag>
                  <a-tag color="red" v-else-if="Number(record.adjust < 0)">${{ record.adjust }}</a-tag>
                  <a-tag v-else>N/A</a-tag>
                </template>
              </a-table-column>
              <a-table-column title="Action" v-if="status === 2 || status === 3 || status === 4" fixed="right"
                :width="150">
                <template #cell="{ record }">
                  <a-tooltip content="Label">
                    <a-button shape="circle" @click="preview(record.view)" class="m-r-8">
                      <icon-eye />
                    </a-button>
                  </a-tooltip>
                  <a-popconfirm content="Are you sure to cancel shipments you selected?" type="warning"
                    ok-text="Cancel Shipment(s)" cancel-text="Back" @ok="cancelSingle(record.key)" class="warnPop"
                    style="width:276px;">
                    <a-tooltip content="Cancel">
                      <a-button v-if="record.cancel" shape="circle" class="m-r-8">
                        <icon-stop />
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                  <a-tooltip content="New Order">
                    <a-button v-if="record.newOrderId > 0" shape="circle" type="primary"
                      @click="newOrderDetail(record.newOrderId)" class="m-r-8">
                      <icon-file />
                    </a-button>
                  </a-tooltip>
                  <a-tooltip content="Detail">
                    <a-button shape="circle" @click="checkingId(record.tracking, record.view)">
                      <icon-right />
                    </a-button>
                  </a-tooltip>
                </template>
              </a-table-column>
            </template>
          </a-table>
        </a-config-provider>
      </div>
    </div>
    <div class="addnew-footer" v-if="form.operate_id.length > 0">
      <div v-if="status === 2 || status === 3 || status === 4">
        <a-space>
          <a-checkbox v-model="checkedValue" @change="changmsg" class="itemNum">
            {{ form.operate_id.length }} Shipments selected in this oder.
          </a-checkbox>
          <span v-if="status === 3 || status === 4">
            <a-space>
              <a-button @click="moreChange" v-if="isChange" shape="round" status="warning">Change Address</a-button>
              <a-popconfirm content="Are you sure you want to call back?" type="warning" ok-text="Call Back"
                cancel-text="Cancel" @ok="moreCallBack" class="warnPop">
                <a-button shape="round" status="warning" v-if="isCall">Call Back
                </a-button>
              </a-popconfirm>
              <a-popconfirm content="Are you sure to cancel shipments you selected?" type="warning"
                ok-text="Cancel Shipment(s)" cancel-text="Back" @ok="moreCancel" class="warnPop" style="width:276px;">
                <a-button shape="round" status="warning" v-if="isCancel">Cancel
                </a-button>
              </a-popconfirm>
            </a-space>
          </span>
          <a-popconfirm content="Are you sure to cancel shipments you selected?" type="warning"
            ok-text="Cancel Shipment(s)" cancel-text="Back" @ok="moreCancel" class="warnPop" style="width:276px;">
            <a-button v-if="status === 2 && form.operate_id.length > 0" shape="round" status="warning">Cancel
            </a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </div>
    <!-- 修改地址的弹框 -->
    <div id="change-address-frame">
      <a-modal v-model:visible="visible" ok-text="Save" cancel-text="Reset" title-align="start" :render-to-body="false"
        @cancel="handleCancel" :width="520">
        <template #title>
          <div class="change-title">
            <span class="til">Change Address</span>
            <span class="track">Tracking:{{ detailData.val.tracking_id }}</span>
          </div>
        </template>
        <a-form :model="addressform" layout="vertical">
          <a-form-item field="name" label="Address">
            <a-config-provider :locale="enUS">
              <a-select @change="toAddressVal" v-model="recipient">
                <a-option v-for="item, i in toAddress.option" :value="item.name" :key="i">{{ item.name }}
                </a-option>
              </a-select>
            </a-config-provider>
          </a-form-item>
        </a-form>
        <a-form :model="form" ref="formRef" size="medium" layout="vertical">
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="to_name" label="Name" :rules="[
                { required: true, message: 'Name is required' },
                { maxLength: 35, minLength: 2, message: '2-35 characters' }
              ]">
                <a-input v-model="form.to_name" placeholder="Required 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="to_companyanme" label="Company Name"
                :rules="[{ maxLength: 35, minLength: 2, message: '2-35 characters' }]">
                <a-input v-model="form.to_companyanme" placeholder="Optional 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item field="to_street1" label="Address Line1" :rules="[
                { required: true, message: 'Address line1 is required' },
                { maxLength: 35, minLength: 4, message: '4-35 characters' }
              ]">
                <a-input v-model="form.to_street1" placeholder="Required 4-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item field="to_street2" label="Address Line2"
                :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters' }]">
                <a-input v-model="form.to_street2" placeholder="Optional 1-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="to_zip" label="Zip Code" :rules="[
                {
                  required: true,
                  type: 'number',
                  message: 'Zip code is required'
                },
                { minLength: 5, maxLength: 10, message: '5-10 characters' }
              ]" :validate-trigger="['blur']">
                <a-config-provider :locale="enUS">
                  <a-select allow-search placeholder="Required 5-10 characters" v-model="form.to_zip" @change="fromChange"
                    @search="fromZipSearch" allow-clear :loading="fromLoading" :options="fromOptions" autocomplete="off">
                    <template #empty>
                      <a-empty v-if="(fromIsempty === true)" />
                      <div class="empty" v-if="(fromZiperror === true)">
                        <a-space>
                          <icon-info-circle />
                          Zip error
                        </a-space>
                      </div>
                      <div class="noarea" v-if="(fromNoarea === true)">
                        <icon-empty />
                        Not in the delivery service area
                      </div>
                    </template>
                  </a-select>
                </a-config-provider>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="to_city" label="City" :rules="[
                { required: true, message: 'City is required' },
                { maxLength: 35, minLength: 2, message: '2-35 characters' }
              ]">
                <a-input v-model="form.to_city" placeholder="Required 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="to_state" label="State" :rules="[{ required: true, message: 'state is required' }]">
                <a-config-provider :locale="enUS">
                  <a-select v-model="form.to_state" placeholder="Please select" allow-search>
                    <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="to_country" label="Country"
                :rules="[{ required: true, message: 'Country is required' }]">
                <a-select v-model="form.to_country" placeholder="Please select" disabled>
                  <a-option value="US">United State</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item field="to_phone" label="Phone Number"
                :rules="[{ minLength: 10, maxLength: 15, message: '10-15 characters' }]">
                <a-input v-model="form.to_phone" placeholder="Optional 5-10 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="to_extension" label="Extension"
                :rules="[{ minLength: 1, maxLength: 6, message: 'must be 1-6 characters' }]">
                <a-input v-model="form.to_extension" placeholder="Optional" />
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item field="to_email" label="Email" :rules="[
                {
                  match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                  message: 'email validation failed'
                }
              ]">
                <a-input v-model="form.to_email" placeholder="Optional" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <template #footer>
          <div class="change-footer">
            <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
              @ok="resetForm" class="warnPop">
              <a-button shape="round">Reset</a-button>
            </a-popconfirm>
            <a-button type="primary" shape="round" html-type="submit" @click="submitChange">Change Address
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
    <!-- 点击tracking的弹框 -->
    <div id="click-teacking">
      <a-drawer v-model:visible="trackingVisible" ok-text="Change Address" cancel-text="Return" title-align="start"
        :render-to-body="false" @cancel="trackingCancel" :width="465">
        <template #title>
          <a-space>
            <a-tag color="orange" v-if="trackData.trackVal.category === 'Awaiting Shipment'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="blue" v-if="trackData.trackVal.category === 'In Transit'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="red" v-if="trackData.trackVal.category === 'Exceptional'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="green" v-if="trackData.trackVal.category === 'Delivered'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="cyan" v-if="trackData.trackVal.category === 'Dispatching'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag v-if="trackData.trackVal.category === 'Canceled'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="purple" v-if="trackData.trackVal.category === 'Callback'">{{
              trackData.trackVal.category
            }}</a-tag>
            <a-tag color="purple" v-if="trackData.trackVal.category === 'Change Address'">{{
              trackData.trackVal.category
            }}</a-tag>
            <span>Tracking :{{ trackData.trackVal.tracking_id }}</span>
            <icon-copy @click="copyVal(trackData.trackVal.tracking_id)" />
          </a-space>
        </template>
        <!-- <div id="orderDetailMap" class="rolad-line"></div> -->
        <div class="proofImg" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.photograph">
          <div class="proof-til">Proof of receipt</div>
          <img :src="proofReceipt" alt="" />
          <div class="proof-footer">
            <a-space size="large">
              <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10" viewBox="0 0 12 10"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                    fill="white" />
                </svg></a>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="padding-right:20px;" @click="downloadImg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
                  fill="white" />
              </svg>
            </a-space>
          </div>
        </div>
        <a-card style="margin-top:20px;">
          <template #title>
            <!-- Order ID#{{ trackData.trackVal.order_unique_id }}
            <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" /> -->
            <div style="display:flex;justify-content:space-between;">
              <div>
                Order ID#{{ trackData.trackVal.order_unique_id }}
                <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" />
              </div>
              <a-button v-if="trackData.trackVal.can_add_note === 1" shape="round" size="mini" @click="openNote">+ Add
                Notes</a-button>
            </div>
          </template>
          <a-timeline>
            <a-timeline-item
              :label="trackData.trackVal.sender.city + ',' + trackData.trackVal.sender.state + ',' + trackData.trackVal.sender.country"
              lineType="dashed">
              Shipper:
            </a-timeline-item>
            <a-timeline-item lineType="dashed"
              :label="trackData.trackVal.recipient.city + ',' + trackData.trackVal.recipient.state + ',' + trackData.trackVal.recipient.country"
              dotColor="#C9CDD4">
              Recipient:
            </a-timeline-item>
          </a-timeline>
          <div class="notice" v-if="trackData.trackVal.notes.length > 0">
            <div class="til">Notes:</div>
            <div class="notice-cont" v-for="note, i in trackData.trackVal.notes" :key="i">
              <div class="who">From: {{ note.from_desc }}</div>
              <div class="cont">{{ note.content }}</div>
              <div class="time">{{ note.created_at }}</div>
            </div>
          </div>
        </a-card>
        <div class="logistics">
          <div class="road-steps">Details of Logistics</div>
          <div class="proof" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.autograph">
            Proof of receipt:
            <a-tooltip content="Download">
              <a-button shape="circle" style="margin-left:10px;" @click="downloadsign">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.5 5.57816V0.25H5.5V5.5787L6.91448 4.16422L7.62159 4.87132L5.00027 7.49264L2.37895 4.87132L3.08605 4.16422L4.5 5.57816ZM1.5 8.75V7.75H0.5V9.75H9.5V7.75H8.5V8.75H1.5Z"
                    fill="#4E5969" />
                </svg>
              </a-button>
            </a-tooltip>
            <!-- <a-popover :content-style="{ padding: 0 }">
              <template #title>
                <div class="proof-title">Proof of receipt</div>
              </template>
              <img :src="proofReceipt" alt="" />
              <template #content>
                <div class="content-img">
                  <img :src="proofReceipt" alt="" />
                  <div class="proof-footer">
                    <a-space size="large">
                      <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10"
                          viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                            fill="white" />
                        </svg></a>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        style="padding-right:20px;" @click="downloadImg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
                          fill="white" />
                      </svg>
                    </a-space>
                  </div>
                </div>
              </template>
            </a-popover> -->
          </div>
        </div>
        <div class="logs">
          <a-timeline>
            <a-timeline-item v-for="item in  trackData.trackVal.logs" :key="item" :label="item.date" dotColor="#ECECEC">
              {{ item.description }}<br />
              <a-typography-text type="secondary" :style="{ fontSize: '12px', marginTop: '4px' }">{{ item.location }}
              </a-typography-text>
            </a-timeline-item>
          </a-timeline>
        </div>
        <template #footer>
          <div class="operate-btn">
            <a-space>
              <a-button shape="circle" @click="LookTrackLabel">
                <icon-eye />
              </a-button>
              <a-popconfirm content="Are you sure to Call Back?
                          You can go to Call Back Order
                           to view the order(s)." type="warning" ok-text="Call Back" cancel-text="Cancel"
                @ok="trackCallBack(trackData.trackVal.shipment_id)" class="warnPop" style="width:276px;">
                <a-button v-if="trackData.trackVal.can_callback" status="warning" shape="round">
                  Call Back</a-button>
              </a-popconfirm>
              <a-button v-if="trackData.trackVal.can_claim" shape="round" status="warning"
                @click="goClaim">Claim</a-button>
              <a-popconfirm content="Are you sure to cancel shipments you selected?
                           You can't undo this action." type="warning" ok-text="Cancel Shipment(s)" cancel-text="Back"
                @ok="trackCancel(trackData.trackVal.shipment_id)" class="warnPop" style="width:276px;">
                <a-button v-if="trackData.trackVal.can_cancel" shape="round" status="warning">Cancel</a-button>
              </a-popconfirm>
              <a-button v-if="trackData.trackVal.can_change_address" shape="round" status="warning"
                @click="trackChangeAddress(trackData.trackVal.shipment_id)" class="warnPop">Change Address
              </a-button>
              <a-button v-if="trackData.trackVal.revised_order_id > 0" type="primary"
                @click="originalTracking(trackData.trackVal.revised_order_id)" shape="round">New Order
              </a-button>
            </a-space>
          </div>
        </template>
      </a-drawer>
    </div>
    <!-- 添加备注的弹框 -->
    <div id="notePop">
      <a-modal v-model:visible="noteVisible" ok-text="Save" cancel-text="Reset" :render-to-body="false"
        @cancel="cancelNote" :width="520" title="Add Notes">
        <a-form :model="noteForm" ref="noteRef" size="medium" layout="vertical">
          <a-form-item field="visible" label="Visible to whom">
            <a-checkbox-group v-model="noteForm.visible">
              <a-checkbox v-for="who, k in whoVisible" :value="k" :key="who">{{ who }}</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item field="content" label="Content" :rules="[
            { required: true, message: 'Please enter' }
          ]" :validate-trigger="['blur']">
            <a-textarea v-model="noteForm.content" placeholder="Please enter" allow-clear />
          </a-form-item>
        </a-form>
        <template #footer>
          <div class="change-footer">
            <a-space>
              <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
                @ok="resetNote" class="warnPop">
                <a-button shape="round">Reset</a-button>
              </a-popconfirm>
              <a-button type="primary" shape="round" html-type="submit" @click="submitNote">Submit
              </a-button>
            </a-space>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted, nextTick } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { Message, Notification, Button } from '@arco-design/web-vue'
import { IconCopy, IconDoubleDown, IconDoubleUp, IconEye, IconRight, IconFile, IconStop, IconInfoCircle, IconEmpty } from '@arco-design/web-vue/es/icon'
// 引入API
import { orderDetail, orderCancel, duplicateOrder, createReturnLabel, markAsShipped, printLabel, addressList, changeAddress, shipmentCancel, shipmentBatchCancel, shipmentCallback, getZipCode, searchLoginTracking, shipmentAddNote } from '@/api/order.js'
// import { searchTarckingId } from '@/api/homepage.js'
import local from '@/utils/local'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
// 使用loading
import { load } from '@/utils/loading.js'
// 使用pinia
import { mainStore } from '@/store/index.js'
// 获取公共信息
const state = JSON.parse(local.get('configInfo')).state
const route = useRoute()
const router = useRouter()
let status = ref()
let orderId = route.query.orderId
const visible = ref(false)
const trackingVisible = ref(false)
const loading = ref(false)
const store = mainStore()
// 控制按钮状态
const isChange = ref(false)
const isCall = ref(false)
const isCancel = ref(false)
const addressform = reactive({
  address: ''
})
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const form = reactive({
  to_name: '',
  to_address: '',
  to_companyanme: '',
  to_phone: '',
  to_street1: '',
  to_street2: '',
  to_city: '',
  to_state: '',
  to_country: 'US',
  to_zip: '',
  to_email: '',
  to_extension: '',
  operate_id: []
})
// 详情列表
let detailData = reactive({ val: {} })
// 详情charge
let detailCahrge = reactive({ val: {} })
// 详情package
let detailPackage = reactive({ val: [] })
let firstDetail = reactive({ val: [] })
let settleDetail = ref({})
let unsettleDetail = ref({})
// 订单总价
let totalPrice = ref(0)
// 导航选中
let navselect = ref(1)
// 获取订单详情
const getOrderDetail = async () => {
  loading.value = true
  form.operate_id = []
  const msg = await orderDetail({
    order_id: orderId
  })
  if (msg.code === 0) {
    loading.value = false
    detailData.val = msg.data
    settleDetail.value = msg.data.settled_detail
    unsettleDetail.value = msg.data.unsettled_detail
    detailCahrge.val = msg.data.quote_detail
    status.value = msg.data.status
    totalPrice.value = msg.data.estimate_shipping_charge
    firstDetail.val = msg.data.shipments.map((item, k) => {
      return {
        key: item.id,
        no: k + 1,
        demension: `${item.length}x${item.width}x${item.height}`,
        weight: item.weight,
        status: item.category,
        sku: item.sku,
        insurance: item.insurance,
        estcost: item.estimate_shipping_charge,
        refund: item.refundAmount,
        actcost: item.actual_shipping_charge,
        adjust: item.adjust_amount,
        tracking: item.tracking_id,
        callBack: item.can_callback,
        cancel: item.can_cancel,
        change: item.can_change_address,
        claim: item.can_claim,
        revisedOrder: item.revised_order_id,
        category: item.category,
        quoteDetail: item.quote_detail,
        view: item.label_file_name,
        newOrderId: item.revised_order_id
      }
    })
    if (navselect.value === 1) {
      detailPackage.val = firstDetail.val
    }
    if (navselect.value === 2) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Awaiting Shipment')
    }
    if (navselect.value === 3) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'In Transit')
    }
    if (navselect.value === 4) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Dispatching')
    }
    if (navselect.value === 5) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Delivered')
    }
    if (navselect.value === 6) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Exceptional')
    }
    if (navselect.value === 7) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Callback')
    }
    if (navselect.value === 8) {
      detailPackage.val = firstDetail.val.filter(item => item.category === 'Change Address')
    }
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 调用获取详情
getOrderDetail()
// 取消订单
async function cancelOrder () {
  load.show('Canceling the order(s)...')
  const msg = await orderCancel({
    order_id: orderId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been canceled.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 打印订单
async function print () {
  load.show('Printing...')
  const msg = await printLabel({
    order_id: orderId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Printed.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 复制订单
async function duplicate () {
  load.show('Duplicating...')
  const msg = await duplicateOrder({
    order_id: orderId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Copied.'
    })
    store.Golist = 1
    router.push('/order/list/orderlist')
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// return 订单
async function returnLable () {
  load.show('Creating return Order(s)...')
  const msg = await createReturnLabel({
    order_id: orderId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Return order has been created.'
    })
    store.Golist = 1
    router.push('/order/list/orderlist')
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 标记为已发货
async function shipped () {
  load.show('Loading...')
  const msg = await markAsShipped({
    order_ids: [orderId]
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been marked as shipped.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}

// const script = ref('')
// onMounted(() => {
//   script.value = document.createElement('script')
// })
const currentView = ref('')
// 起点
// let startAddress = ref('')
// 终点
// let endAddress = ref('')
// 点击tracking id
const drawerTracking = ref('')
// 签收pingzheng
let proofReceipt = ref('')
async function checkingId (tracking, url) {
  drawerTracking.value = tracking
  currentView.value = url
  console.log(drawerTracking.value, currentView.value)
  const msg = await searchLoginTracking({
    trackingId: drawerTracking.value
  })
  if (msg.code === 0) {
    if (!msg.data.photograph) {
      proofReceipt.value = msg.data.pod_file
    } else {
      proofReceipt.value = msg.data.photograph
    }
    trackData.trackVal = msg.data
    // let lastLocation = msg.data.lastLocation
    // startAddress.value = lastLocation.street1 + ',' + lastLocation.street2 + ',' + lastLocation.city + ',' + lastLocation.state + ',' + lastLocation.zip + ',' + lastLocation.country
    // let endLocation = msg.data.recipient
    // endAddress.value = endLocation.street1 + ',' + endLocation.street2 + ',' + endLocation.city + ',' + endLocation.state + ',' + endLocation.zip + ',' + endLocation.country
    // script.value.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCYZLGxJ3yCFkGJmmT1gOOAh-_7KP1H5f8&callback=orderDetailMap&libraries=&v=weekly'
    // script.value.setAttribute('defer', 'defer')
    // document.body.appendChild(script.value)
    nextTick(function () {
      trackingVisible.value = true
      // if (directionsService !== undefined) {
      //   calculateAndDisplayRoute(directionsService, directionsRenderer)
      // }
    })
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// let directionsService
// let directionsRenderer
// window.orderDetailMap = function () {
//   directionsService = new google.maps.DirectionsService();
//   directionsRenderer = new google.maps.DirectionsRenderer();
//   const map = new google.maps.Map(document.getElementById("orderDetailMap"), {
//     zoom: 10
//   })
//   directionsRenderer.setMap(map);
//   // 刚进入页面时渲染函数
//   calculateAndDisplayRoute(directionsService, directionsRenderer)
// }
// function calculateAndDisplayRoute (directionsService, directionsRenderer) {
//   directionsService.route({
//     origin: startAddress.value,
//     destination: endAddress.value,
//     optimizeWaypoints: true,
//     travelMode: google.maps.TravelMode.DRIVING
//   }, (response, status) => {
//     if (status === "OK") {
//       directionsRenderer.setDirections(response)
//     }
//   }
//   )
// }
// tracking 弹框的数据
let trackData = reactive({
  trackVal: {
    sender: {},
    recipient: {},
    notes: []
  }
})
// 关闭TRACKING弹框
const trackingCancel = () => {
  visible.value = false
}
// 导航
const navlist = reactive({
  'All': 1, // eslint-disable-line quote-props
  'Awaiting Shipment': 2,
  'In Transit': 3, // eslint-disable-line quote-props
  'Dispatching': 4, // eslint-disable-line quote-props
  'Delivered': 5, // eslint-disable-line quote-props
  'Exceptional': 6, // eslint-disable-line quote-props
  'Call back': 7, // eslint-disable-line quote-props
  'Change address': 8 // eslint-disable-line quote-props
})
// 点击导航
const clickMenu = (val) => {
  form.operate_id = []
  navselect.value = val
  getOrderDetail()
}
// 获取recipient Address
const toAddress = reactive({
  option: []
})
const getToAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 2,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    toAddress.option = msg.data.data
  }
}
const recipient = ref('')
// recipient Address 选择回填
const toAddressVal = (val) => {
  recipient.value = val
  toAddress.option.forEach((item) => {
    if (item.name === val) {
      form.to_name = item.name
      form.to_company = item.company
      form.to_street1 = item.street1
      form.to_street2 = item.street2
      form.to_city = item.city
      form.to_state = item.state
      form.to_zip = item.zip
      form.to_phone = item.phone
      form.to_extension = item.extension
      form.to_email = item.email
    }
  })
}
const formRef = ref(null)
// 关闭修改地址弹框
const handleCancel = () => {
  visible.value = false
}
// 打开批量修改地址弹框
const moreChange = () => {
  getToAddress()
  visible.value = true
  // 回填
  form.to_name = detailData.val.to_name
  form.to_address = detailData.val.to_address
  form.to_companyanme = detailData.val.to_company
  form.to_phone = detailData.val.to_phone
  form.to_street1 = detailData.val.to_street1
  form.to_street2 = detailData.val.to_street2
  form.to_city = detailData.val.to_city
  form.to_state = detailData.val.to_state
  form.to_zip = detailData.val.to_zip
  form.to_email = detailData.val.to_email
  form.to_extension = detailData.val.to_extension
}
// 打开tracking 修改地址
const trackChangeAddress = (shipmentId) => {
  getToAddress()
  visible.value = true
  form.operate_id.push(shipmentId)
  // 回填
  form.to_name = detailData.val.to_name
  form.to_address = detailData.val.to_address
  form.to_companyanme = detailData.val.to_company
  form.to_phone = detailData.val.to_phone
  form.to_street1 = detailData.val.to_street1
  form.to_street2 = detailData.val.to_street2
  form.to_city = detailData.val.to_city
  form.to_state = detailData.val.to_state
  form.to_zip = detailData.val.to_zip
  form.to_email = detailData.val.to_email
  form.to_extension = detailData.val.to_extension
}
// 重置修改表单、
const resetForm = () => {
  recipient.value = ''
  formRef.value.resetFields()
}
// 提交修改
const submitChange = () => {
  formRef.value.validate(async (record) => {
    if (!record) {
      visible.value = false
      trackingVisible.value = false
      load.show('Changing Address...')
      const msg = await changeAddress({
        to_name: form.to_name,
        to_company: form.to_companyanme,
        to_street1: form.to_street1,
        to_street2: form.to_street2,
        to_city: form.to_city,
        to_state: form.to_state,
        to_zip: form.to_zip,
        is_residential: null,
        to_country: form.to_country,
        to_phone: form.to_phone,
        to_extension: form.to_extension,
        to_email: form.to_email,
        shipment_ids: form.operate_id
      })
      if (msg.code === 0) {
        load.hide()
        Notification.success({
          title: 'Success',
          content: 'Changed Address.'
        })
        // 清空id
        form.operate_id = []
        navselect.value = 8
        getOrderDetail()
      } else if (msg.code === 11) {
        load.hide()
        // 余额不足
        Notification.warning({
          id: 'payListdetail1',
          title: 'Insufficient Balance!',
          content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
          duration: 0,
          closable: true,
          footer: <Button type="primary" size="small" shape="round" onClick={closeNotification1}>
            Recharge
          </Button>
        })
        form.operate_id = []
        navselect.value = 8
        getOrderDetail()
      } else {
        load.hide()
        Notification.error({
          title: 'Error',
          content: msg.message
        })
      }
    }
  })
}
const closeNotification1 = () => {
  Notification.remove('payListdetail1')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 多选
const changmsg = (checked) => {
  checkedValue.value = checked
  if (checked) {
    form.operate_id = []
    detailPackage.val.forEach((item) => {
      form.operate_id.push(item.key)
    })
    isChange.value = detailPackage.val.every(item => {
      return item.change === true
    })
    isCall.value = detailPackage.val.every(item => {
      return item.callBack === true
    })
    isCancel.value = detailPackage.val.every(item => {
      return item.cancel === true
    })
  } else {
    // checkedValue.value = false
    form.operate_id = []
    isChange.value = false
    isCall.value = false
    isCancel.value = false
  }
}
// 单选
function selectVal (rowKeys) {
  form.operate_id = rowKeys
  if (rowKeys.length === detailPackage.val.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  let currentArr = []
  for (let i = 0; i < rowKeys.length; i++) {
    for (let j = 0; j < detailPackage.val.length; j++) {
      if (rowKeys[i] === detailPackage.val[j].key) {
        currentArr.push(detailPackage.val[j])
      }
    }
  }
  if (currentArr.length > 0) {
    isChange.value = currentArr.every(item => {
      return item.change === true
    })
    isCall.value = currentArr.every(item => {
      return item.callBack === true
    })
    isCancel.value = currentArr.every(item => {
      return item.cancel === true
    })
  } else {
    isChange.value = false
    isCall.value = false
    isCancel.value = false
  }
}
// tracking call back
const trackCallBack = async (shipmentId) => {
  form.operate_id.push(shipmentId)
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: form.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    form.operate_id = []
    navselect.value = 7
    getOrderDetail()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payListdetail2',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification2}>
        Recharge
      </Button>
    })
    form.operate_id = []
    navselect.value = 7
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification2 = () => {
  Notification.remove('payListdetail2')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量call back
const moreCallBack = async () => {
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: form.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    form.operate_id = []
    navselect.value = 7
    getOrderDetail()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payListdetail3',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification3}>
        Recharge
      </Button>
    })
    form.operate_id = []
    navselect.value = 7
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification3 = () => {
  Notification.remove('payListdetail3')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// tracking cancel
const trackCancel = async (shipmentId) => {
  trackingVisible.value = false
  load.show('Canceling the shipment...')
  const msg = await shipmentCancel({
    id: shipmentId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment has been canceled.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量删除
const moreCancel = async () => {
  load.show('Canceling the shipment...')
  const msg = await shipmentBatchCancel({
    ids: form.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment has been canceled.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const newOrderDetail = (newId) => {
  let routerUrl = router.resolve({ path: '/order/list/listdetail', query: { orderId: newId } })
  window.open(routerUrl.href, '_blank')
}
// 判断是去哪个页面，然后清掉localStorage
onBeforeRouteLeave((to, from, next) => {
  if (to.path === '/order/list/orderlist') {
    next()
  } else {
    local.del('searchData')
    next()
  }
})
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const checkedValue = ref(false)
const tableWidth = ref(0)
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口宽
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
  }
})
const preview = (url) => {
  window.open(url, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const LookTrackLabel = () => {
  window.open(currentView.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
// call back和change address的订单详情
const originalTracking = (trackingid) => {
  trackingVisible.value = false
  orderId = trackingid
  getOrderDetail()
}
const downloadsign = () => {
  window.open(proofReceipt.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const downloadImg = () => {
  let image = new Image()
  image.setAttribute("crossOrigin", "anonymous")
  image.onload = function () {
    let canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext("2d")
    context.drawImage(image, 0, 0, image.width, image.height)
    let url = canvas.toDataURL("image/png")
    let a = document.createElement("a")
    let event = new MouseEvent("click")
    a.download = "POD_" + trackData.trackVal.tracking_id
    a.href = url
    a.dispatchEvent(event)
  }
  image.src = proofReceipt.value + '?' + Math.random
}
// 单个 cancel
const cancelSingle = async (cancelId) => {
  load.show('Canceling ...')
  const msg = await shipmentCancel({
    id: cancelId
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment has been canceled.'
    })
    getOrderDetail()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const goOriginal = (originalId) => {
  router.push('/order/list/listdetail?orderId=' + originalId)
}

const numReg = /^[0-9]*$/
const fromOptions = ref([])
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromLoading = ref(false)
const fromaddress = ref([])
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromLoading.value = false
        fromNoarea.value = false
        fromOptions.value = []
        fromaddress.value = msg.data
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromLoading.value = false
        fromOptions.value = []
        fromaddress.value = []
        fromNoarea.value = true
      }
    }
  }
}
const fromChange = (value) => {
  console.log(fromOptions.value)
  if (fromaddress.value !== []) {
    fromaddress.value.forEach((item) => {
      if (item.value === value) {
        form.to_city = item.city
        form.to_state = item.state
        form.to_zip = item.zip
      }
    })
  }
}
// 投诉
const goClaim = () => {
  router.push('/usercenter/claim')
}
// 添加备注
const whoVisible = JSON.parse(local.get('configInfo')).shipment_note_visible
const noteVisible = ref(false)
const noteRef = ref(null)
const noteForm = reactive({
  visible: [],
  content: ''
})
const openNote = () => {
  noteVisible.value = true
}
const cancelNote = () => {
  noteVisible.value = false
  noteRef.value.resetFields()
}
const resetNote = () => {
  noteRef.value.resetFields()
}
const submitNote = () => {
  noteRef.value.validate(async (record) => {
    if (!record) {
      const msg = await shipmentAddNote({
        visible: noteForm.visible,
        shipment_id: trackData.trackVal.shipment_id,
        content: noteForm.content
      })
      console.log(msg)
      if (msg.code === 0) {
        Notification.success('Submitted.')
        noteVisible.value = false
        noteRef.value.resetFields()
        const msg = await searchLoginTracking({
          trackingId: drawerTracking.value
        })
        if (msg.code === 0) {
          if (!msg.data.photograph) {
            proofReceipt.value = msg.data.pod_file
          } else {
            proofReceipt.value = msg.data.photograph
          }
          trackData.trackVal = msg.data
          nextTick(function () {
            trackingVisible.value = true
          })
        } else {
          Notification.error({
            title: 'Error',
            content: msg.message
          })
        }
      } else {
        Notification.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

:deep(.arco-spin) {
  display: block;
}

.add-container {
  position: relative;

  .top-title {
    height: 60px;
    line-height: 60px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copy-color {
      color: var(--color-text-2);
      font-size: 14px;
    }

    .create-time {
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-3);
    }

    .detail-status {
      margin-left: 16px;
      font-weight: 500;
      font-size: 14px;
      color: var(--color-text-1);
      padding: 5px 8px;
      background-color: var(--color-fill-2);
      border-radius: 8px;
    }
  }

  .detail-box {
    padding: 24px;

    .collapse-title {
      color: var(--color-text-1);
      font-weight: 500;
      font-size: 16px;
      height: 46px;
    }

    :deep(.arco-collapse-item-content) {
      background-color: #fff;
    }

    .shipments-title {
      font-weight: 500;
      font-size: 16px;
      color: var(--color-text-1);
    }

    :deep(.arco-menu-light .arco-menu-item.arco-menu-selected) {
      background-color: var(--color-fill-2);
    }

    :deep(.arco-menu-selected-label) {
      background-color: transparent;
    }

    .menu-list {
      width: 990px;
    }
  }

  .status {
    font-size: 14px;
    padding: 1px 8px;
    border-radius: 8px;
  }

  .default-text {
    font-size: 14px;
    padding: 1px 8px;
    border-radius: 8px;
    color: var(--color-text-1);
    background-color: var(--color-fill-2);
  }

  .dlivered {
    color: rgb(var(--green-6));
    background-color: rgb(var(--green-1));
  }

  .exceptional {
    color: rgb(var(--red-6));
    background-color: rgb(var(--red-1));
  }

  .addnew-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 24px;
    border-top: 1px solid var(--color-border-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 999;
  }

  .empty-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    div {
      color: rgba(28, 31, 35, 0.8);
      font-weight: 400;
      font-size: 14px;
      padding-top: 30px;
    }
  }
}

// #orderDetailMap {
//   height: 255px;
// }
.proofImg {
  position: relative;

  .proof-til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
    padding-bottom: 12px;
  }

  img {
    height: 255px;
    width: 100%;
    border-radius: 12px;
  }

  .proof-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 6px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    text-align: right;
    line-height: 50px;
    margin-right: 20px;
    z-index: 2;
    border-radius: 0 0 12px 12px;

    svg {
      cursor: pointer;
    }
  }
}

:deep(.logs .arco-timeline .arco-timeline-item:first-child .arco-timeline-item-content) {
  font-weight: bold;

  .arco-typography {
    font-weight: normal;
  }
}

// 备注
.notice {

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .notice-cont {
    padding-top: 16px;

    .who {
      margin-bottom: 4px;
      color: var(--color-text-1);
      font-size: 14px;
      line-height: 1.5715;
    }

    .cont {
      font-size: 12px;
      margin-top: 4px;
      color: var(--color-text-2);
      line-height: 1.5715;
    }

    .time {
      color: var(--color-text-3);
      font-size: 12px;
      line-height: 1.667;
    }
  }
}

// 日志
.logistics {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;

  .road-steps {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .proof {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-text-3);

    img {
      width: 32px;
      height: 32px;
      padding-left: 8px;
    }
  }
}

.proof-title {
  padding: 11px 16px;
}

.content-img {
  position: relative;

  img {
    width: 360px;
    height: 204px;
  }

  // .proof-footer {
  //   position: absolute;
  //   width: 360px;
  //   height: 50px;
  //   left: 0;
  //   bottom: 6px;
  //   background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  //   text-align: right;
  //   line-height: 50px;
  //   margin-right: 20px;
  //   z-index: 2;

  //   svg {
  //     cursor: pointer;
  //   }
  // }
}

.change-title {
  text-align: center;
  width: 100%;

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--Text-5);
  }

  .track {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
    padding-left: 8px;
  }
}

.change-footer {
  text-align: center;
  width: 100%;
}

.margin-l-10 {
  cursor: pointer;
  width: 12px;
  height: 12px;
  color: #C9CDD4;
  padding-left: 4px;
}

.m-r-8 {
  margin-right: 8px;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
